/**
 *
 * CreateTeam
 *
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectCreateTeam,
  selectAllowedDomains,
  selectNewTeamName,
  selectNewTeamId,
  selectCreatingNewTeam
} from "./selectors";
import { Container, Row, Col, Form } from "react-bootstrap";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import "./styles.css";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { selectSafeToRedirect, selectUsersList } from "./selectors";
import { createTeamSaga } from "./saga";
import { AddUserInput } from "app/components/AddUserInput";
import { LinkButton } from "app/components/LinkButton";
import rightArrow from "resources/images/arrow-right-white.svg";
import { AddTeamLeadInput } from "app/components/AddTeamLeadInput";
import { LimitedTextAreaInput } from "app/components/LimitedTextAreaInput";
import { isValidEmail } from "utils/helper_functions/isValidEmail";
import { selectUser, selectUserName } from "app/containers/Global/selectors";

export function CreateTeam() {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer
  });
  useInjectSaga({
    key: sliceKey,
    saga: createTeamSaga
  });

  /* Style constants */
  const linkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "1.43",
    color: "#425cc7"
  };

  /* local state variables */
  const [isAdmin, setIsAdmin] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [leadValue, setLeadValue] = useState<string>("");
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [tempInputValue, setTempInputValue] = useState("");
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [leadValidationError, setLeadValidationError] = useState("");
  const [tempInputValidationError, setTempInputValidationError] = useState("");
  const [customMessage, setCustomMessage] = useState("");

  // we have to set up an interface and read the variable passed from the
  // location hook to determine if this comes from the admin page or not.
  // TODO: figure out a better way to do this.
  interface stateType {
    from: string;
  }
  const { state } = useLocation<stateType>();
  const fromAdmin = state?.from === "admin";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createTeam = useSelector(selectCreateTeam);
  const allowedDomains = useSelector(selectAllowedDomains);
  const safeToRedirect = useSelector(selectSafeToRedirect);
  const newTeamName = useSelector(selectNewTeamName);
  const newTeamId = useSelector(selectNewTeamId);
  const usersList = useSelector(selectUsersList);
  const creatingNewTeam = useSelector(selectCreatingNewTeam);

  /****************************************************************************
   * Selectors                                                               *
   ****************************************************************************/
  const user = useSelector(selectUser);
  const userName = useSelector(selectUserName);

  const dispatch = useDispatch();

  /****************************************************************************
   * Hooks
   ****************************************************************************/

  useEffectOnMount(() => {
    if (user) {
      const companyAccountId = user.companyAccountId;
      if (companyAccountId) {
        dispatch(actions.getAllowedDomains(companyAccountId));
      }
      dispatch(actions.getUsersList());
      setIsAdmin(user.tmgRoleId === 1);
    }
  });

  const history = useHistory();

  // remove temp validation error if the user has removed all temp input values
  useEffect(() => {
    if (tempInputValue === "" && tempInputValidationError !== "") {
      setTempInputValidationError("");
    }
  }, [tempInputValue, tempInputValidationError]);

  /****************************************************************************
   *  Handlers
   ****************************************************************************/

  const onSubmit = () => {
    if (!creatingNewTeam) {
      if (leadValue) {
        if (isValidEmail(leadValue)) {
          const leadValueDomain = leadValue.split("@")[1].toLowerCase();
          if (!allowedDomains.includes(leadValueDomain)) {
            setLeadValidationError(
              `${leadValueDomain} is not included in your company domain..`
            );
            return false;
          }
        } else {
          setLeadValidationError(`${leadValue} is not a valid email address.`);
          return false;
        }
      }

      // the following conditional in enacted if the user has typed in an entry and
      // clicked the Invite button without typing a delimiter
      if (tempInputValue && !tempInputValue.includes(",")) {
        if (isValidEmail(tempInputValue)) {
          if (
            !allowedDomains.includes(tempInputValue.split("@")[1].toLowerCase())
          ) {
            setTempInputValidationError(
              `${tempInputValue} is not included in your company domain.`
            );
            return false;
          }
        } else {
          setTempInputValidationError(
            `${tempInputValue} is not a valid email.`
          );
          return false;
        }
        const emailAddressesClone = emailAddresses;
        emailAddressesClone.push(tempInputValue);
        setEmailAddresses(emailAddressesClone);
      }

      const formData = {
        // if a teamName has been set by the user, use that.  if not, use the placeholder
        // text as the team name.
        teamName: teamName
          ? teamName
          : userName?.trim()
          ? `${userName}'s Team`
          : `${"Team"}`,
        subTestIds: [43],
        // viewAllWorkplaceInsights,
        teamLead: leadValue || null,
        emails: emailAddresses,
        noTeamLead: !leadValue ? 1 : 0,
        customMessage
      };
      window.ChurnZero.push([
        "trackEvent",
        "Team Created",
        `${userName} created ${teamName}`
      ]);
      dispatch(actions.createNewTeam(formData));
    }
  };

  const handleCancelButton = () => {
    history.push("/");
  };

  const isOnTeam =
    user?.emailAddress === leadValue ||
    (user?.emailAddress && emailAddresses.includes(user.emailAddress));

  return (
    <>
      <Helmet>
        <title>Create a Team</title>
        <meta name="description" content="Create a Team Form" />
      </Helmet>

      <Container fluid="lg" className="create-team-container">
        <Row className="mb-3">
          <Col>
            <span className="create-team-header">Create Team</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="teamName">
              <Form.Label className="form-label-text mb-1">
                Team Name
              </Form.Label>
              <Form.Control
                type="text"
                value={teamName}
                onChange={event => setTeamName(event.target.value)}
                placeholder={userName?.trim() ? `${userName}'s Team` : "Team"}
                style={{ height: "45px" }}
              />
            </Form.Group>
          </Col>
        </Row>
        {isAdmin && (
          <Row className="mb-3">
            <Col lg={12} md={12} sm={12}>
              <Form.Label className="form-label-text mb-0">
                Team Lead
              </Form.Label>
              <AddTeamLeadInput
                usersList={usersList}
                allowedDomains={allowedDomains}
                leadValue={leadValue}
                setLeadValue={setLeadValue}
                leadValidationError={leadValidationError}
                setLeadValidationError={setLeadValidationError}
              />
              {leadValidationError.length > 0 ? (
                <p className="mt-0 ml-1 error-message">{leadValidationError}</p>
              ) : null}
            </Col>
          </Row>
        )}
        <Row className="mb-0 mt-4">
          <Col className="mb-0">
            <p className="form-text mb-2">
              Invite your colleagues to Talent Insights to start learning how to
              work better together.
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={12} md={12} sm={12}>
            <AddUserInput
              usersList={usersList}
              allowedDomains={allowedDomains}
              emailAddresses={emailAddresses}
              setEmailAddresses={setEmailAddresses}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              tempInputValue={tempInputValue}
              setTempInputValue={setTempInputValue}
              tempInputValidationError={tempInputValidationError}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <LimitedTextAreaInput
              limit={10000}
              formLabel={"Add a message (optional)"}
              placeholder={
                "Enter a message that you would like to include in the invitation"
              }
              rows={4}
              customMessage={customMessage}
              setCustomMessage={setCustomMessage}
            />
          </Col>
        </Row>
        <Row className="mt-5 mb-4">
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="text-center m-auto order-12 order-md-1"
          >
            <LinkButton
              text="Cancel"
              showArrow=""
              handler={handleCancelButton}
              isLoading={false}
              style={linkButtonStyle}
            />
            <button
              className="send-invites-button ml-5"
              disabled={
                Object.keys(validationErrors).length > 0 || creatingNewTeam
              }
              onClick={onSubmit}
            >
              Send Invitations
              <img
                src={rightArrow}
                alt="right arrow"
                className="create-team-arrow"
              />
            </button>
          </Col>
        </Row>
      </Container>
      {safeToRedirect && newTeamName && newTeamId ? (
        <Redirect
          to={{
            pathname: fromAdmin ? "/admin" : "/",
            state: {
              teamName: newTeamName,
              teamId: newTeamId,
              isOnTeam
            }
          }}
        />
      ) : null}
    </>
  );
}
